$font-path: "../fonts";
@use '@angular/material' as mat;
@import "prismjs/themes/prism.css";
@import "prismjs/plugins/line-numbers/prism-line-numbers.css";
@import "prismjs/plugins/line-highlight/prism-line-highlight.css";
@import "./assets/styles/variables";
@import "./assets/styles/font-files";
@import "./assets/styles/resets";

// material-icons
@import "~material-icons/iconfont/material-icons.css";

// partials
@import "./scss/auth-forms";

// Common styles for Angular Material. Included so that we only have to load a
// single CSS file for Angular Material in our app.
// Don't include this anywhere else!
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy($custom-typography);`
@include mat.core();
@include mat.all-component-typographies($custom-typography);
@include mat.all-legacy-component-typographies($custom-typography);
@include mat.legacy-core();

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($candy-app-theme);
@include mat.all-legacy-component-themes($candy-app-theme);

// @include mat.typography-hierarchy($custom-typography);

// Responsive type maybe?
@media screen and (max-width: 767px) {
  // TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
  //  The following line adds:
  //    1. Default typography styles for all components
  //    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
  //  If you specify typography styles for the components you use elsewhere, you should delete this line.
  //  If you don't need the default component typographies but still want the hierarchy styles,
  //  you can delete this line and instead use:
  //    `@include mat.legacy-typography-hierarchy($mobile-typography);`
  @include mat.core();
  @include mat.all-component-typographies($mobile-typography);
  @include mat.all-legacy-component-typographies($mobile-typography);
  @include mat.legacy-core();
}

a {
  color: $amaranth;
  font-weight: 500;
}

ul {
  list-style: none;
  padding: unset;
}

.active-route {
  font-weight: 700;
  background-color: mat.get-color-from-palette(mat.$gray-palette, 100);
}

.project-card-wrapper {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax((290px), 1fr));
  grid-gap: 24px;
}

.mat-stroked-button {
  background-color: #fefefe;
}

header.page-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: -16px;
  margin-bottom: 20px;

  > * {
    margin: 0;
  }
}

.l-body {
  max-width: 1100px;
  padding-left: 24px;
  padding-right: 24px;
  margin-top: 32px;
  margin-left: auto;
  margin-right: auto;

  &--full-width {
    max-width: 100%;
  }

  @media screen and (max-width: $small - 1) {
    padding-left: 15px;
    padding-right: 15px;
  }
}

.u-hidden {
  display: none;
}

.mat-display-1,
.mat-display-2 {
  margin-bottom: 1.2em;
}

.marginless {
  margin: 0;
}

.full-width {
  width: 100%;
}

.flex-common {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tt-wrapping {
  overflow-wrap: break-word;
  height: auto;
}

.ngx-charts-tooltip-content.type-tooltip {
  font-family: $font-sans-serif !important;
}

.line-highlight {
  max-width: 100%;
}

.mat-mdc-header-cell {
  font-size: 12px;
  color: #0000008a;
}

.pagination-row {
  td {
    border-top: 1px solid #0000001f;
  }
}

// Taken from https://stackoverflow.com/a/57759753
.small-icon-button {
  width: 24px !important;
  height: 24px !important;
  padding: 0px !important;
  display: inline-flex !important;
  align-items: center;
  justify-content: center;

  & > *[role="img"] {
    width: 16px;
    height: 16px;
    font-size: 16px;

    svg {
      width: 16px;
      height: 16px;
    }
  }
}

.medium-icon-button {
  width: 36px !important;
  height: 36px !important;
  padding: 0px !important;

  display: inline-flex !important;
  align-items: center;
  justify-content: center;

  & > *[role="img"] {
    width: 24px;
    height: 24px;
    font-size: 24px;

    svg {
      width: 24px;
      height: 24px;
    }
  }
}
